import { ButtonArrowIcon } from "@/comps/ai-tools/icons"
import { BorderInput } from "@/comps/border-input"
import Button, { GradientGrayscaleButton } from "@/comps/button"
import { DropDown } from "@/comps/dropdown"
import { Logo } from "@/comps/header/icon"
import { CustomThemedResource } from "@/comps/image"
import { useValidator } from "@/comps/input"
import { ErrorMessage } from "@/comps/message"
import {
	fetchOfferingPrices,
	getAdId,
	getAdvancedButtonText,
	getBasicButtonText,
	getCurrentPackId,
	pricesSchema,
} from "@/comps/subscription-popup"
import { SquareSwitch } from "@/comps/toggle"
import {
	PaymentSessionData,
	PAYMENT_DATA_KEY,
	useAdjustLogger,
} from "@/utils/adjust"
import { assetUrl } from "@/utils/cdn"
import useAuth, {
	forceRefreshToken,
	logInWithUserCredential,
	useAuthUpdater,
} from "@/utils/client-auth"
import {
	LoginPage,
	loginPageSchema,
} from "@/utils/login-redirect"
import { NotificationContext } from "@/utils/notification"
import {
	SSRTemporaryRedirect,
	wrapSSRAuth,
} from "@/utils/ssr-auth"
import { isEmail, notEmpty } from "@/utils/validators"
import axios from "axios"
import clsx from "clsx"
import crypto from "crypto"
import { FirebaseError } from "firebase/app"
import {
	AuthProvider,
	FacebookAuthProvider,
	getAuth,
	getRedirectResult,
	GoogleAuthProvider,
	OAuthProvider,
} from "firebase/auth"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import {
	FormEvent,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react"
import { useQuery } from "react-query"
import priceJson from "statics/offering-prices.json"
import { v4 } from "uuid"
import { z } from "zod"
import { Meta } from "../comps"
import {
	PaymentStripeUrlRequest,
	PaymentStripeUrlResponse,
} from "./api/payment-stripe-url"
import { OptionType, Quiz } from "./api/quiz"
import { VerifyEmailRes } from "./api/verify-email"

const plans = pricesSchema.parse(priceJson)

interface LoginProps {
	redirect_url: string | string[] | null
	type: LoginPage
	expired: boolean
}

type MetaTitleTypes = "txt_login_title" | "txt_signup_title"

export default function Login(props: LoginProps) {
	const { redirect_url, type, expired } = props

	const [page, setPage] = useState<LoginPage>(type)
	const [email, setEmail] = useState("")
	const [newPassword, setNewPassword] = useState("")
	const [quiz, setQuiz] = useState<Quiz | null>(null)
	const [requested, setRequested] = useState(false)
	const [metaTitle, setMetaTitle] =
		useState<MetaTitleTypes>(
			type === "signup"
				? "txt_signup_title"
				: "txt_login_title",
		)

	const { t } = useTranslation()
	const { userInfo, setUserInfo } = useAuth()
	const router = useRouter()
	const logAdjust = useAdjustLogger()

	useEffect(() => {
		const handleRedirectResult = async () => {
			setRequested(true)
			const auth = getAuth()

			try {
				const result = await getRedirectResult(auth)
				if (result) {
					const newUserInfo = await logInWithUserCredential(
						result,
					)
					setUserInfo(newUserInfo)

					if (newUserInfo.details.promptOnboarding) {
						setPage("username")
						return
					} else {
						const quizParams = {
							uid: newUserInfo.userId,
						}
						const quiz = await axios
							.post<Quiz | null>("/api/quiz", quizParams)
							.then((res) => res.data)
						if (quiz) {
							setQuiz(quiz)
							setPage("quiz")
							return
						}
					}
					let url = "/"

					if (typeof redirect_url === "string") {
						url = redirect_url
					}

					const hashEmail = (email: string | null) => {
						if (!email) return null
						return crypto
							.createHash("sha256")
							.update(email)
							.digest("hex")
					}

					if (type === "signup") {
						const hashedEmail = hashEmail(result.user.email)

						logAdjust?.logEventWithPayload(
							"w_did_sign_up",
							{
								from: url,
								email: hashedEmail,
							},
						)
					} else if (type === "signin") {
						logAdjust?.logEventWithPayload(
							"w_did_sign_in",
							{
								from: url,
							},
						)
					}
					await router.push(url)

					setEmail("")
				}
				setRequested(false)
			} catch (error) {
				console.error(
					"Error handling redirect result",
					error,
				)
				setRequested(false)
			}
		}

		if (router.isReady) {
			handleRedirectResult()
		}
	}, [
		logAdjust,
		redirect_url,
		router,
		setUserInfo,
		type,
		userInfo,
	])

	return (
		<>
			<Meta
				title={t(metaTitle)}
				description={t("txt_login_description")}
			/>
			<div className="flex min-h-[100svh] w-screen flex-row overflow-hidden">
				{/* Left Panel */}
				<LeftPanel />

				{/* Right Panel */}
				<div className="relative z-[2] flex min-h-[100svh] w-full flex-col items-center justify-start gap-1 overflow-hidden bg-color-surface-cell tablet:justify-between desktop:h-auto desktop:min-h-screen desktop:w-[50%] desktop:overflow-visible">
					<div className="hidden h-4 w-full tablet:block" />

					<div className="relative flex w-full flex-1 items-center justify-start tablet:justify-center">
						<SignInSection
							page={page}
							email={email}
							setEmail={setEmail}
							setPage={setPage}
							redirectUrl={redirect_url}
							setQuiz={setQuiz}
							setMetaTitle={setMetaTitle}
							requested={requested}
							setRequested={setRequested}
						/>
						<SignUpSection
							page={page}
							email={email}
							setEmail={setEmail}
							setPage={setPage}
							redirectUrl={redirect_url}
							newPassword={newPassword}
							setNewPassword={setNewPassword}
							setQuiz={setQuiz}
							setMetaTitle={setMetaTitle}
							requested={requested}
							setRequested={setRequested}
						/>
						<VerifySection
							page={page}
							email={email}
							setEmail={setEmail}
							setPage={setPage}
							redirectUrl={redirect_url}
							newPassword={newPassword}
							setNewPassword={setNewPassword}
							setQuiz={setQuiz}
							setMetaTitle={setMetaTitle}
							requested={requested}
							setRequested={setRequested}
						/>
						<UsernameSection
							page={page}
							email={email}
							setEmail={setEmail}
							setPage={setPage}
							redirectUrl={redirect_url}
							newPassword={newPassword}
							setNewPassword={setNewPassword}
							setQuiz={setQuiz}
							setMetaTitle={setMetaTitle}
							requested={requested}
							setRequested={setRequested}
						/>
						{quiz && (
							<QuizSection
								page={page}
								quiz={quiz}
								setPage={setPage}
								requested={requested}
								setRequested={setRequested}
							/>
						)}
						<PlanSection
							setPage={setPage}
							page={page}
							redirectUrl={redirect_url}
							setMetaTitle={setMetaTitle}
						/>
						{/* <GenerateSection
              page={page}
              redirectUrl={redirect_url}
              setPage={setPage}
            /> */}
						<ForgotSection
							page={page}
							email={email}
							setEmail={setEmail}
							setPage={setPage}
							redirectUrl={redirect_url}
							expired={expired}
							setQuiz={setQuiz}
							setMetaTitle={setMetaTitle}
							requested={requested}
							setRequested={setRequested}
						/>
						<SuccessSection page={page} />
					</div>

					{(page === "signin" || page === "signup") && (
						<div className="hidden tablet:block">
							<div className="h-4 w-full"></div>
							<span
								className={clsx(
									"hidden max-w-full self-center pb-7 text-center text-[15px] transition-opacity tablet:block",
								)}>
								<span>
									{t("txt_by_signing_up_you_accept")}
								</span>
								<a
									href="/privacy-policy"
									className="mx-1 text-[#007BFF]">
									{t("txt_terms_of_use")}
								</a>
								<span>{t("lbl_and")}</span>
								<a
									href="/privacy-policy"
									className="mx-1 text-[#007BFF]">
									{t("txt_privacy_policy")}
								</a>
							</span>
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export function LeftPanel() {
	const { t } = useTranslation()

	return (
		<div className="relative z-[1] hidden min-h-screen desktop:block desktop:w-[50%]">
			<div className="pointer-events-none absolute left-0 top-0 h-full w-full select-none">
				<img
					src={assetUrl("/login/background.webp")}
					alt="background image"
					className="h-full w-full object-cover"
				/>
			</div>

			<div className="relative flex h-full w-full flex-col items-center justify-between pb-[58px]">
				<div className="mt-[59px]">
					<a href="/">
						<img
							src={assetUrl("/login/logo.webp")}
							alt="logo"
							className="h-[71px] w-[171px]"
						/>
					</a>
				</div>
				<img
					src={assetUrl("/login/accounts.webp")}
					alt="accounts"
					className="pointer-events-none h-[150px] w-[299px] select-none object-cover"
				/>

				<p className="pointer-events-none w-[458px] select-none text-center text-[40px] font-800 text-blue-800 dark:text-blue-200">
					{t("txt_video_creation_platform")}
				</p>

				<img
					src={assetUrl("/login/icons.webp")}
					alt="accounts"
					className="pointer-events-none w-full select-none object-cover"
				/>
			</div>
		</div>
	)
}

const sectionOrder = {
	plan: 0,
	quiz: 1,
	username: 2,
	verify: 3,
	signup: 4,
	signin: 5,
	forgot: 6,
	success: 7,
} as const

export function handleStopScroll(event: Event) {
	event.stopPropagation()
	event.preventDefault()
}

const facebookLogo = (
	<svg
		width="25"
		height="24"
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M23.667 12.0669C23.667 5.95335 18.7436 1 12.667 1C6.59038 1 1.66699 5.95335 1.66699 12.0669C1.66699 17.6004 5.65893 22.1968 10.9372 23V15.2799H8.1428V12.0669H10.9372V9.6572C10.9372 6.89047 12.5783 5.3286 15.0622 5.3286C16.3041 5.3286 17.546 5.55172 17.546 5.55172V8.27383H16.171C14.796 8.27383 14.3525 9.1217 14.3525 10.0142V12.0669H17.413L16.9251 15.2799H14.3525V23C19.6307 22.1968 23.667 17.6004 23.667 12.0669Z"
			fill="var(--facebook-color)"
		/>
	</svg>
)

const googleLogo = (
	<svg
		width="25"
		height="24"
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.85707 11.9992C5.85707 11.2977 5.97351 10.6251 6.18158 9.99433L2.54164 7.21484C1.83222 8.65513 1.43262 10.2782 1.43262 11.9992C1.43262 13.719 1.83188 15.3408 2.54012 16.7803L6.17804 13.9954C5.97199 13.3675 5.85707 12.6974 5.85707 11.9992Z"
			fill="#FBBC05"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.2483 5.61743C13.7723 5.61743 15.1488 6.15743 16.2303 7.04101L19.3765 3.89922C17.4593 2.23011 15.0013 1.19922 12.2483 1.19922C7.97422 1.19922 4.30087 3.64339 2.54199 7.21482L6.18176 9.9943C7.02045 7.44854 9.41113 5.61743 12.2483 5.61743Z"
			fill="#EA4335"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.2483 18.3808C9.4113 18.3808 7.02062 16.5497 6.18193 14.0039L2.54199 16.7829C4.30087 20.3548 7.97422 22.799 12.2483 22.799C14.8862 22.799 17.4048 21.8623 19.295 20.1073L15.84 17.4363C14.8651 18.0504 13.6375 18.3808 12.2483 18.3808Z"
			fill="#34A853"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22.5717 11.9987C22.5717 11.3605 22.4733 10.6732 22.3258 10.0352H12.248V14.2078H18.049C17.7589 15.6306 16.9695 16.7242 15.8397 17.436L19.2947 20.107C21.2802 18.2643 22.5717 15.519 22.5717 11.9987Z"
			fill="#4285F4"
		/>
	</svg>
)

const appleLogo = (
	<svg
		width="25"
		height="24"
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18.6661 12.13C18.6368 9.23826 21.0626 7.85131 21.171 7.78124C19.8077 5.81983 17.6838 5.5507 16.9279 5.52008C15.1218 5.33957 13.402 6.5669 12.4857 6.5669C11.5714 6.5669 10.1557 5.54652 8.65806 5.57251C6.68786 5.60128 4.87187 6.70008 3.858 8.43551C1.81189 11.9282 3.33457 17.1043 5.32834 19.939C6.30306 21.3246 7.46547 22.8837 8.99051 22.8266C10.4604 22.7695 11.0149 21.8911 12.7918 21.8911C14.5686 21.8911 15.0676 22.8266 16.6223 22.7974C18.203 22.7691 19.2051 21.3854 20.1718 19.9942C21.2908 18.3845 21.7515 16.8259 21.7789 16.747C21.7444 16.7312 18.6977 15.5828 18.6661 12.13Z"
			fill="var(--apple-color)"
		/>
		<path
			d="M15.7447 3.64487C16.5534 2.67878 17.1014 1.33638 16.9524 0C15.7853 0.0464019 14.3725 0.763312 13.5345 1.72847C12.7838 2.58505 12.1273 3.9488 12.3032 5.26105C13.6052 5.36035 14.9331 4.6091 15.7447 3.64487Z"
			fill="var(--apple-color)"
		/>
	</svg>
)

interface SignInProvider {
	name: string
	icon: ReactNode
	provider: () => AuthProvider
}

const authProviders: SignInProvider[] = [
	{
		name: "Google",
		icon: googleLogo,
		provider: () => {
			const provider = new GoogleAuthProvider()
			provider.addScope("email")

			return provider
		},
	},
	{
		name: "Apple",
		icon: appleLogo,
		provider: () => {
			const provider = new OAuthProvider("apple.com")
			provider.addScope("email")

			return provider
		},
	},
	{
		name: "Facebook",
		icon: facebookLogo,
		provider: () => {
			const provider = new FacebookAuthProvider()
			provider.addScope("email")

			return provider
		},
	},
]

export interface SignInSignUpSectionProps {
	redirectUrl: string | string[] | null
	page: LoginPage
	setPage: (page: LoginPage) => void
	email: string
	setEmail: (email: string) => void
	expired?: boolean
	setQuiz: (quiz: Quiz) => void
	setMetaTitle: (meta: MetaTitleTypes) => void
	requested: boolean
	setRequested: (v: boolean) => void
}

export interface SignUpSectionProps {
	redirectUrl: string | string[] | null
	page: LoginPage
	setPage: (page: LoginPage) => void
	email: string
	setEmail: (email: string) => void
	newPassword: string
	setNewPassword: (password: string) => void
	setQuiz: (quiz: Quiz) => void
	setMetaTitle: (meta: MetaTitleTypes) => void
	requested: boolean
	setRequested: (v: boolean) => void
}

export interface QuizSectionProps {
	page: LoginPage
	setPage: (page: LoginPage) => void
	quiz: Quiz
	requested: boolean
	setRequested: (v: boolean) => void
}

export function SuccessSection({
	page,
}: {
	page: LoginPage
}) {
	const { t } = useTranslation()

	return (
		<div
			className={clsx(
				"absolute right-0 top-0 tablet:right-[calc(50%-185px)]",
				"h-full w-full tablet:w-[375px]",
				"flex items-center justify-center",
				"transition-all duration-500",
				page === "success"
					? ["opacity-100 delay-200", "translate-y-0"]
					: [
							"pointer-events-none opacity-0",
							sectionOrder[page] > sectionOrder.success
								? "translate-y-24"
								: "-translate-y-24",
					  ],
			)}>
			<div className="flex flex-col items-center gap-3 text-[14px] font-500">
				<img
					src={assetUrl("/general/success-check.svg")}
					className="aspect-square w-[60px]"
					alt="success check"
				/>
				<div className="px-4 text-center">
					<p>{t("txt_please_check_email")}</p>
				</div>
			</div>
		</div>
	)
}

export function ForgotSection(
	props: SignInSignUpSectionProps,
) {
	const {
		page,
		email,
		setEmail,
		setPage,
		expired,
		requested,
		setRequested,
	} = props

	const { t } = useTranslation()
	const logAdjust = useAdjustLogger()

	const emailValidator = useValidator(email, [
		notEmpty,
		isEmail,
	])

	const notCurrent = page !== "forgot"

	const { notify } = useContext(NotificationContext)
	const { sendPasswordResetEmail } = useAuthUpdater()

	async function handleReset(
		event: FormEvent<HTMLFormElement>,
	) {
		event.preventDefault()
		setRequested(true)

		try {
			await sendPasswordResetEmail(email)
			logAdjust?.logEvent("w_dp_send_password_reset_link")

			setPage("success")
			setEmail("")
		} catch (error) {
			console.error(error)

			if (error instanceof FirebaseError) {
				switch (error.code) {
					case "auth/user-not-found":
						notify(
							<ErrorMessage>
								{t("txt_user_not_found")}
							</ErrorMessage>,
						)
						break

					default:
						notify(
							<ErrorMessage>
								{t("txt_unexpected_error")}
							</ErrorMessage>,
						)
				}
			} else {
				notify(
					<ErrorMessage>
						{t("txt_unexpected_error")}
					</ErrorMessage>,
				)
			}
		}

		setRequested(false)
	}

	return (
		<form
			className={clsx(
				"absolute right-0 top-0 tablet:right-[calc(50%-185px)]",
				"h-full w-full tablet:w-[375px]",
				"flex items-center justify-center",
				"transition-all duration-500",
				page === "forgot"
					? ["opacity-100 delay-200", "translate-y-0"]
					: [
							"pointer-events-none opacity-0",
							sectionOrder[page] > sectionOrder.forgot
								? "translate-y-24"
								: "-translate-y-24",
					  ],
			)}
			onSubmit={handleReset}>
			<div className="flex w-full flex-col items-stretch gap-3 px-4 tablet:relative tablet:w-[375px] tablet:px-0">
				<h3 className="mb-4 text-center text-[20px] font-700 text-blue-800">
					{t("txt_forgot_password")}
				</h3>
				<button
					type="button"
					className={clsx(
						"absolute left-2 top-2 tablet:left-0 tablet:top-0",
						"bg-blue-200 hover:bg-blue-100",
						"transition-colors duration-200",
						"h-8 w-8 rounded-full px-3",
					)}
					onClick={() => setPage("signin")}>
					<svg
						width="6"
						height="12"
						viewBox="0 0 6 12"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M5.12969 10.2594L0.871094 6.00078L5.12969 1.74219"
							stroke="var(--color-blue-900)"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>

				<div className="flex flex-col items-stretch">
					<BorderInput
						name="email"
						type="email"
						placeholder={t("lbl_email")}
						className="!bg-color-background"
						value={email}
						disabled={requested || notCurrent}
						onChange={function (event) {
							setEmail(event.currentTarget.value)
						}}
						onBlur={emailValidator.validate}
					/>
				</div>

				<Button
					type="submit"
					className="mt-4 w-full"
					text="common:txt_send_password_reset"
					loading={requested}
					disabled={requested || notCurrent}
					primary
				/>

				<div className="flex flex-col items-center gap-1">
					<span
						className={clsx(
							"text-[13px] font-400",
							expired
								? "text-color-error"
								: "text-blue-700",
						)}>
						{expired
							? t("txt_link_expired")
							: t("txt_we_will_email")}
					</span>
					<strong className="block h-8 w-full truncate text-center">
						{email}
					</strong>
				</div>
			</div>
		</form>
	)
}

function getErrorName(error: unknown): string {
	if (error instanceof FirebaseError) {
		switch (error.code) {
			case "auth/wrong-password":
				return "txt_wrong_password"
			case "auth/user-not-found":
				return "txt_user_not_found"
			case "auth/too-many-requests":
				return "txt_too_many_requests"
		}
	}
	return "txt_unknown_error"
}

export function SignInSection(
	props: SignInSignUpSectionProps,
) {
	const {
		page,
		email,
		setEmail,
		setPage,
		redirectUrl,
		setQuiz,
		setMetaTitle,
		requested,
		setRequested,
	} = props

	const { t } = useTranslation()
	const router = useRouter()
	const logAdjust = useAdjustLogger()

	const emailValidator = useValidator(email, [
		notEmpty,
		isEmail,
	])
	const [password, setPassword] = useState("")
	const [passwordHidden, setPasswordHidden] = useState(true)

	const notCurrent = page !== "signin"
	const {
		logInWithEmailAndPassword: signInWithEmailAndPassword,
	} = useAuthUpdater()

	async function handleSignIn(
		event: FormEvent<HTMLFormElement>,
	) {
		event.preventDefault()
		if (
			email.trim().length === 0 ||
			password.trim().length === 0
		)
			return
		setRequested(true)

		logAdjust?.logEventWithPayload("w_dp_sign_in", {
			from:
				typeof redirectUrl === "string"
					? redirectUrl
					: "home",
			type: "email",
		})

		try {
			const userInfo = await signInWithEmailAndPassword(
				email,
				password,
			)
			if (userInfo.details.promptOnboarding) {
				setPage("username")
				setRequested(false)
				return
			} else {
				const quizParams = {
					uid: userInfo.userId,
				}
				const quiz = await axios
					.post<Quiz | null>("/api/quiz", quizParams)
					.then((res) => res.data)
				if (quiz) {
					setQuiz(quiz)
					setPage("quiz")
					setRequested(false)
					return
				}
			}
			let url = "/"

			if (typeof redirectUrl === "string") {
				url = redirectUrl
			}

			logAdjust?.logEventWithPayload("w_did_sign_in", {
				from: url,
			})
			await router.push(url)

			setEmail("")
			setPassword("")
			setPasswordHidden(true)
		} catch (error) {
			console.error(error)
			const errorText = getErrorName(error)
			notify(<ErrorMessage>{t(errorText)}</ErrorMessage>)
		}

		setRequested(false)
	}

	const { notify } = useContext(NotificationContext)
	const { logInWithProvider } = useAuthUpdater()

	async function handleProviderSignIn(
		provider: AuthProvider,
		name: string,
	) {
		setRequested(true)

		logAdjust?.logEventWithPayload("w_dp_sign_in", {
			from:
				typeof redirectUrl === "string"
					? redirectUrl
					: "home",
			type: name,
		})

		try {
			logInWithProvider(provider)
			// await logInWithProvider(provider)

			// if (userInfo.details.promptOnboarding) {
			// 	setPage("username")
			// 	setRequested(false)
			// 	return
			// } else {
			// 	const quizParams = {
			// 		uid: userInfo.userId,
			// 	}
			// 	const quiz = await axios
			// 		.post<Quiz | null>("/api/quiz", quizParams)
			// 		.then((res) => res.data)
			// 	if (quiz) {
			// 		setQuiz(quiz)
			// 		setPage("quiz")
			// 		setRequested(false)
			// 		return
			// 	}
			// }
			// let url = "/"

			// if (typeof redirectUrl === "string") {
			// 	url = redirectUrl
			// }

			// logAdjust?.logEventWithPayload("w_did_sign_in", {
			// 	from: url,
			// })

			// await router.push(url)

			// setEmail("")
			// setPassword("")
			// setPasswordHidden(true)
		} catch (error) {
			console.error(error)
			const errorText = getErrorName(error)
			notify(<ErrorMessage>{t(errorText)}</ErrorMessage>)
		}
		setRequested(false)
	}

	return (
		<form
			className={clsx(
				"absolute right-0 top-0 tablet:right-[calc(50%-185px)]",
				"h-full w-full tablet:w-[375px]",
				"flex justify-center tablet:items-center tablet:pt-0",
				"pb-[60px] transition-all duration-500 tablet:pb-0",
				page === "signin"
					? ["opacity-100 delay-200", "translate-y-0"]
					: [
							"pointer-events-none opacity-0",
							sectionOrder[page] > sectionOrder.signin
								? "translate-y-24"
								: "-translate-y-24",
					  ],
			)}
			onSubmit={handleSignIn}>
			<div className="no-scrollbar flex w-full flex-col items-stretch gap-3 overflow-auto  px-4 pt-8 tablet:w-[375px] tablet:px-0 tablet:pt-0">
				<div className="flex w-full items-center justify-center desktop:hidden">
					<Logo showOnMobile />
				</div>
				<h3 className="mb-4 text-center text-[20px] font-700 text-blue-800">
					{t("txt_sign_in_zoomerang")}
				</h3>

				<div className="flex h-[148px] shrink-0 flex-col gap-2 [--apple-color:#121212] [--facebook-color:#345287] dark:[--apple-color:#ffffff] dark:[--facebook-color:#ffffff] tablet:h-[166px]">
					{authProviders.map((authProvider, index) => (
						<button
							key={index}
							type="button"
							className={clsx(
								"relative h-[44px] w-full shrink-0 gap-3 rounded-lg font-500 tablet:h-[50px]",
								"bg-blue-100 hover:bg-blue-200 disabled:hover:bg-blue-100",
								"transition-colors duration-300 ease-in-out",
								"flex flex-1 items-center justify-center",
							)}
							onClick={() =>
								handleProviderSignIn(
									authProvider.provider(),
									authProvider.name,
								)
							}
							disabled={notCurrent || requested}>
							<div className="absolute left-3 top-[9px] tablet:left-[15px] tablet:top-[12px]">
								{authProvider.icon}
							</div>
							<p className=" text-[15px] font-500 text-blue-700">
								{t("fs_continue_with", {
									provider: authProvider.name,
								})}
							</p>
						</button>
					))}
				</div>

				<div className="flex items-center gap-2">
					<div className="h-[1px] flex-1 bg-color-separator" />
					<span className="select-none text-[13px] font-400 text-blue-700">
						{t("lbl_or")}
					</span>
					<div className="h-[1px] flex-1 bg-color-separator" />
				</div>

				<div className="flex flex-col items-stretch">
					<BorderInput
						name="email"
						type="email"
						value={email}
						placeholder={t("lbl_email")}
						disabled={requested || notCurrent}
						className=" !h-11 !bg-color-background tablet:!h-[50px]"
						onChange={function (event) {
							setEmail(event.currentTarget.value)
						}}
						onBlur={emailValidator.validate}
					/>
				</div>

				<div className="relative flex flex-col items-stretch overflow-visible">
					<div className="relative">
						<BorderInput
							name="password"
							placeholder={t("lbl_password")}
							type={passwordHidden ? "password" : "text"}
							disabled={requested || notCurrent}
							className="peer !h-11 w-full !bg-color-background pr-12 tablet:!h-[50px]"
							onChange={(event) =>
								setPassword(event.currentTarget.value)
							}
						/>

						<button
							type="button"
							disabled={
								passwordHidden ||
								notCurrent ||
								password.length === 0
							}
							onClick={() => setPasswordHidden(true)}
							className={clsx(
								"absolute right-2 top-1/2 -translate-y-1/2 p-2",
								"transition-opacity duration-150",
								passwordHidden &&
									"pointer-events-none opacity-0",
								password.length === 0 &&
									"pointer-events-none opacity-0",
							)}>
							<img
								src={assetUrl(
									"/general/eye-icon-closed.svg",
								)}
								alt="show password"
							/>
						</button>

						<button
							type="button"
							disabled={
								!passwordHidden ||
								notCurrent ||
								password.length === 0
							}
							onClick={() => setPasswordHidden(false)}
							className={clsx(
								"absolute right-2 top-1/2 -translate-y-1/2 p-2",
								"transition-opacity duration-150",
								!passwordHidden &&
									"pointer-events-none opacity-0",
								password.length === 0 &&
									"pointer-events-none opacity-0",
							)}>
							<img
								src={assetUrl("/general/eye-icon.svg")}
								alt="hide password"
							/>
						</button>
					</div>
				</div>

				<button
					type="button"
					className={clsx(
						"self-end text-[14px] font-400 text-blue-500 tablet:self-center",
						"underline decoration-blue-500 hover:!decoration-blue-500 tablet:decoration-[transparent]",
						"transition-colors duration-200 ease-in-out",
						"cursor-pointer",
					)}
					onClick={() => {
						logAdjust?.logEvent("w_dp_forgot_password")
						setPage("forgot")
					}}>
					{t("txt_dont_remember_password")}
				</button>

				<Button
					type="submit"
					className="w-full tablet:mt-2"
					text="lbl_signin"
					loading={requested}
					disabled={requested || notCurrent}
					primary
				/>

				<span className="max-w-full self-center pb-4 text-center text-[12px] tablet:hidden tablet:pb-0">
					<span>{t("txt_by_signing_up_you_accept")}</span>
					<a
						href="/privacy-policy"
						className="mx-1 text-[#007BFF]">
						{t("txt_terms_of_use")}
					</a>
					<span>{t("lbl_and")}</span>
					<a
						href="/privacy-policy"
						className="mx-1 text-[#007BFF]">
						{t("txt_privacy_policy")}
					</a>
				</span>

				<div
					className={clsx(
						"fixed bottom-0 left-0 z-[7] w-full bg-color-cell tablet:relative tablet:bg-[transparent]",
						"border-t border-color-separator tablet:border-[transparent]",
						"flex justify-center",
					)}>
					<button
						type="button"
						className="h-[60px] text-[15px] tablet:h-auto"
						onClick={() => {
							setPage("signup")
							setMetaTitle("txt_signup_title")
						}}>
						{t("txt_dont_have_account")}
						<b className="ml-1 font-700 text-primary-500">
							{t("lbl_signup")}
						</b>
					</button>
				</div>
			</div>
		</form>
	)
}

export function SignUpSection(props: SignUpSectionProps) {
	const {
		page,
		email,
		setEmail,
		setPage,
		newPassword: password,
		setNewPassword: setPassword,
		redirectUrl,
		setMetaTitle,
		requested,
		setRequested,
	} = props

	const { t } = useTranslation()
	const logAdjust = useAdjustLogger()

	const notCurrent = page !== "signup"

	const emailValidator = useValidator(email, [
		notEmpty,
		isEmail,
	])
	const [confirm, setConfirm] = useState("")
	const [passwordHidden, setPasswordHidden] = useState(true)
	const [confirmHidden, setConfirmHidden] = useState(true)

	const { notify } = useContext(NotificationContext)
	const { logInWithProvider: signInWithPopup } =
		useAuthUpdater()

	async function checkEmail(
		event: FormEvent<HTMLFormElement>,
	) {
		event.preventDefault()

		if (
			email.trim().length === 0 ||
			password.trim().length === 0
		)
			return

		setRequested(true)

		logAdjust?.logEventWithPayload("w_dp_sign_up", {
			from:
				typeof redirectUrl === "string"
					? redirectUrl
					: "home",
			type: "email",
		})

		try {
			const canContinue = await axios
				.post<VerifyEmailRes>("/api/verify-email", {
					email,
				})
				.then((res) => res.data)

			if (canContinue.status === true) {
				setPage("verify")

				setPasswordHidden(true)
				setConfirm("")
				setConfirmHidden(true)
				setRequested(false)
				return
			} else {
				switch (canContinue.code) {
					case "already_linked":
						notify(
							<ErrorMessage>
								{t("txt_email_already_in_use")}
							</ErrorMessage>,
						)
						break
					case "invalid_email":
						notify(
							<ErrorMessage>
								{t("txt_email_not_valid")}
							</ErrorMessage>,
						)
						break
					default:
						notify(
							<ErrorMessage>
								{t("txt_email_already_in_use")}
							</ErrorMessage>,
						)
						break
				}
				setRequested(false)
			}
		} catch (error) {
			console.error(error)
			notify(
				<ErrorMessage>
					{t("txt_couldnt_create_account")}
				</ErrorMessage>,
			)
		}

		setRequested(false)
	}

	async function handleProviderSignIn(
		provider: AuthProvider,
		name: string,
	) {
		setRequested(true)
		logAdjust?.logEventWithPayload("w_dp_sign_up", {
			from:
				typeof redirectUrl === "string"
					? redirectUrl
					: "home",
			type: name,
		})
		try {
			signInWithPopup(provider)

			// if (userInfo.details.promptOnboarding) {
			// 	setPage("username")
			// 	setRequested(false)
			// 	return
			// } else {
			// 	const quizParams = {
			// 		uid: userInfo.userId,
			// 	}
			// 	const quiz = await axios
			// 		.post<Quiz | null>("/api/quiz", quizParams)
			// 		.then((res) => res.data)
			// 	if (quiz) {
			// 		setQuiz(quiz)
			// 		setPage("quiz")
			// 		setRequested(false)
			// 		return
			// 	}
			// }
			// let url = "/"

			// if (typeof redirectUrl === "string") {
			// 	url = redirectUrl
			// }

			// const hashEmail = (email: string | null) => {
			// 	if (!email) return null
			// 	return crypto
			// 		.createHash("sha256")
			// 		.update(email)
			// 		.digest("hex")
			// }

			// const hashedEmail = hashEmail(email)

			// logAdjust?.logEventWithPayload("w_did_sign_up", {
			// 	from: url,
			// 	email: hashedEmail,
			// })
			// await router.push(url)

			// setEmail("")
			// setPassword("")
		} catch (error) {
			console.error(error)
			notify(
				<ErrorMessage>
					{t("txt_couldnt_create_account")}
				</ErrorMessage>,
			)
		}
		setRequested(false)
	}

	const notSamePassword = password !== confirm

	return (
		<form
			className={clsx(
				"absolute right-0 top-0 tablet:right-[calc(50%-185px)]",
				"h-full w-full tablet:w-[375px]",
				"flex flex-col justify-start tablet:items-center tablet:justify-center tablet:pt-0",
				"pb-[60px] transition-all duration-500 tablet:pb-0",
				page === "signup"
					? ["opacity-100 delay-200", "translate-y-0"]
					: [
							"pointer-events-none opacity-0",
							sectionOrder[page] > sectionOrder.signup
								? "translate-y-24"
								: "-translate-y-24",
					  ],
			)}
			onSubmit={checkEmail}>
			<div className="no-scrollbar flex h-full w-full flex-col items-stretch gap-3 overflow-auto px-4 pt-8 tablet:h-auto tablet:w-[375px] tablet:overflow-visible tablet:px-0 tablet:pt-0">
				<div className="flex w-full items-center justify-center desktop:hidden">
					<Logo showOnMobile />
				</div>
				<div className="flex w-full items-center justify-center">
					<h3
						className="mb-4 w-[280px] text-center text-[20px] font-700 text-blue-800"
						id="signup-title">
						{t("txt_you_need_account")}
					</h3>
				</div>

				<div className="flex h-[148px] shrink-0 flex-col gap-2 [--apple-color:#121212] [--facebook-color:#345287] dark:[--apple-color:#ffffff] dark:[--facebook-color:#ffffff] tablet:h-[166px]">
					{authProviders.map((authProvider, index) => (
						<button
							key={index}
							type="button"
							className={clsx(
								"relative h-[44px] w-full shrink-0 gap-3 rounded-lg font-500 tablet:h-[50px]",
								"bg-blue-100 hover:bg-blue-200 disabled:hover:bg-blue-100",
								"transition-colors duration-300 ease-in-out",
								"flex flex-1 items-center justify-center",
							)}
							onClick={() =>
								handleProviderSignIn(
									authProvider.provider(),
									authProvider.name,
								)
							}
							disabled={notCurrent || requested}>
							<div className="absolute left-3 top-[9px] tablet:left-[15px] tablet:top-[12px]">
								{authProvider.icon}
							</div>
							<p className=" text-[15px] font-500 text-blue-700">
								{t("fs_continue_with", {
									provider: authProvider.name,
								})}
							</p>
						</button>
					))}
				</div>

				<div className="flex items-center gap-2">
					<div className="h-[1px] flex-1 bg-color-separator" />
					<span className="select-none text-[13px] font-400 text-blue-700">
						{t("lbl_or")}
					</span>
					<div className="h-[1px] flex-1 bg-color-separator" />
				</div>

				<BorderInput
					name="email"
					className=" !h-11 !bg-color-background tablet:!h-[50px]"
					placeholder={t("lbl_email")}
					type="email"
					value={email}
					disabled={requested || notCurrent}
					onChange={function (event) {
						setEmail(event.currentTarget.value)
					}}
					onBlur={emailValidator.validate}
				/>

				<div className="relative flex flex-col items-stretch overflow-visible">
					<div className="relative">
						<BorderInput
							name="password"
							placeholder={t("lbl_password")}
							type={passwordHidden ? "password" : "text"}
							value={password}
							disabled={requested || notCurrent}
							className="peer !h-11 w-full !bg-color-background pr-12 tablet:!h-[50px]"
							onChange={(event) =>
								setPassword(event.currentTarget.value)
							}
						/>

						<PasswordCheckPopup password={password} />

						<button
							type="button"
							disabled={
								passwordHidden ||
								notCurrent ||
								password.length === 0
							}
							onClick={() => setPasswordHidden(true)}
							className={clsx(
								"absolute right-2 top-1/2 -translate-y-1/2 p-2",
								"transition-opacity duration-150",
								passwordHidden &&
									"pointer-events-none opacity-0",
								password.length === 0 &&
									"pointer-events-none opacity-0",
							)}>
							<img
								src={assetUrl(
									"/general/eye-icon-closed.svg",
								)}
								alt="show password"
							/>
						</button>

						<button
							type="button"
							disabled={
								!passwordHidden ||
								notCurrent ||
								password.length === 0
							}
							onClick={() => setPasswordHidden(false)}
							className={clsx(
								"absolute right-2 top-1/2 -translate-y-1/2 p-2",
								"transition-opacity duration-150",
								!passwordHidden &&
									"pointer-events-none opacity-0",
								password.length === 0 &&
									"pointer-events-none opacity-0",
							)}>
							<img
								src={assetUrl("/general/eye-icon.svg")}
								alt="hide password"
							/>
						</button>
					</div>
				</div>

				<div className="relative flex flex-col items-stretch overflow-visible">
					<div className="relative">
						<BorderInput
							name="password"
							placeholder={t("txt_confirm_password")}
							type={confirmHidden ? "password" : "text"}
							className="peer !h-11 w-full !bg-color-background pr-12 tablet:!h-[50px]"
							value={confirm}
							disabled={requested || notCurrent}
							onChange={(event) =>
								setConfirm(event.currentTarget.value)
							}
						/>

						<button
							type="button"
							disabled={confirmHidden || notCurrent}
							onClick={() => setConfirmHidden(true)}
							className={clsx(
								"absolute right-2 top-1/2 -translate-y-1/2 p-2",
								"transition-opacity duration-150",
								confirmHidden &&
									"pointer-events-none opacity-0",
								confirm.length === 0 &&
									"pointer-events-none opacity-0",
							)}>
							<img
								src={assetUrl(
									"/general/eye-icon-closed.svg",
								)}
								alt="hide password"
							/>
						</button>

						<button
							type="button"
							disabled={!confirmHidden || notCurrent}
							onClick={() => setConfirmHidden(false)}
							className={clsx(
								"absolute right-2 top-1/2 -translate-y-1/2 p-2",
								"transition-opacity duration-150",
								!confirmHidden &&
									"pointer-events-none opacity-0",
								confirm.length === 0 &&
									"pointer-events-none opacity-0",
							)}>
							<img
								src={assetUrl("/general/eye-icon.svg")}
								alt="show password"
							/>
						</button>
					</div>
				</div>

				<Button
					type="submit"
					className="w-full"
					text="lbl_next"
					loading={requested}
					disabled={
						requested ||
						notCurrent ||
						notSamePassword ||
						password.trim().length === 0 ||
						email.trim().length === 0
					}
					primary
				/>
				<span className="max-w-full self-center pb-4 text-center text-[12px] tablet:hidden tablet:pb-0">
					<span>{t("txt_by_signing_up_you_accept")}</span>
					<a
						href="/privacy-policy"
						className="mx-1 text-[#007BFF]">
						{t("txt_terms_of_use")}
					</a>
					<span>{t("lbl_and")}</span>
					<a
						href="/privacy-policy"
						className="mx-1 text-[#007BFF]">
						{t("txt_privacy_policy")}
					</a>
				</span>
			</div>

			<div
				className={clsx(
					"fixed bottom-0 left-0 z-[41] w-full bg-color-cell tablet:relative tablet:z-[30] tablet:mt-2 tablet:bg-[transparent]",
					"border-t border-color-separator tablet:mt-[16px] tablet:border-[transparent]",
					"flex justify-center",
				)}>
				<button
					type="button"
					className="h-[60px] text-[15px] tablet:h-auto"
					onClick={() => {
						setPage("signin")
						setMetaTitle("txt_login_title")
					}}>
					{t("txt_already_have_account")}
					<b className="ml-1 font-700 text-primary-500">
						{t("lbl_signin")}
					</b>
				</button>
			</div>
		</form>
	)
}

export function VerifySection(props: SignUpSectionProps) {
	const {
		page,
		email,
		setPage,
		newPassword: password,
		redirectUrl,
		setQuiz,
		requested,
		setRequested,
	} = props

	const { t } = useTranslation()
	const logAdjust = useAdjustLogger()
	const router = useRouter()

	const [code, setCode] = useState("")
	const [hasError, setHasError] = useState(false)
	const inputRefs = useRef<Array<HTMLInputElement | null>>(
		Array(6).fill(null),
	)

	const notCurrent = page !== "verify"

	const { notify } = useContext(NotificationContext)
	const { createUserWithEmailAndPassword } =
		useAuthUpdater()

	const [timer, setTimer] = useState(60)

	useEffect(() => {
		if (timer === 0 || notCurrent) return

		const intervalId = setInterval(() => {
			setTimer((prevTimer) => prevTimer - 1)
		}, 1000)

		return () => clearInterval(intervalId)
	}, [notCurrent, timer])

	const minutes = Math.floor(timer / 60)
	const seconds = timer % 60
	const timerDisplay = `${minutes
		.toString()
		.padStart(2, "0")}:${seconds
		.toString()
		.padStart(2, "0")}`

	async function handleResend() {
		setTimer(60)

		if (email.trim().length === 0) return

		try {
			await axios
				.post<VerifyEmailRes>("/api/verify-email", {
					email,
				})
				.then((res) => res.data)
		} catch (error) {
			console.error(error)
			notify(
				<ErrorMessage>
					{t("txt_something_wrong")}
				</ErrorMessage>,
			)
		}
	}

	const errorSchema = z.object({
		code: z.string(),
	})

	async function handleSignUp(submitCode: string) {
		if (
			email.trim().length === 0 ||
			password.trim().length === 0 ||
			requested
		)
			return

		setRequested(true)

		try {
			const canContinue = await axios
				.post<boolean>("/api/verify-email-code", {
					email,
					code: submitCode,
				})
				.then((res) => res.data)

			if (canContinue) {
				const userInfo =
					await createUserWithEmailAndPassword(
						email,
						password,
					)

				const hashEmail = (email: string) => {
					return crypto
						.createHash("sha256")
						.update(email)
						.digest("hex")
				}

				const hashedEmail = hashEmail(email)

				logAdjust?.logEventWithPayload("w_did_sign_up", {
					from:
						typeof redirectUrl === "string"
							? redirectUrl
							: "home",
					email: hashedEmail,
				})

				if (userInfo.details.promptOnboarding) {
					setPage("username")
					setRequested(false)
					return
				} else {
					const quizParams = {
						uid: userInfo.userId,
					}
					const quiz = await axios
						.post<Quiz | null>("/api/quiz", quizParams)
						.then((res) => res.data)
					if (quiz) {
						setQuiz(quiz)
						setPage("quiz")
						setRequested(false)
						return
					}
				}
				let url = "/"

				if (typeof redirectUrl === "string") {
					url = redirectUrl
				}

				await router.push(url)
				return
			} else {
				setHasError(true)
				setRequested(false)
				return
			}
		} catch (error) {
			console.error(error)
			try {
				const parsedError = errorSchema.parse(error)
				switch (parsedError.code) {
					case "auth/email-already-in-use":
						notify(
							<ErrorMessage>
								{t("txt_email_already_in_use")}
							</ErrorMessage>,
						)
						break
					case "auth/invalid-email":
						notify(
							<ErrorMessage>
								{t("txt_email_not_valid")}
							</ErrorMessage>,
						)
						break
					case "auth/operation-not-allowed":
						notify(
							<ErrorMessage>
								{t("txt_operation_not_allowed")}
							</ErrorMessage>,
						)
						break
					case "auth/weak-password":
						notify(
							<ErrorMessage>
								{t("txt_password_too_weak")}
							</ErrorMessage>,
						)
						break
					default:
						notify(
							<ErrorMessage>
								{t("txt_couldnt_create_account")}
							</ErrorMessage>,
						)
						break
				}
			} catch (validationError) {
				notify(
					<ErrorMessage>
						{t("txt_couldnt_create_account")}
					</ErrorMessage>,
				)
			}
		}

		setRequested(false)
	}

	const handleChange = (index: number, value: string) => {
		const sanitizedValue = value.replace(/\D/g, "")

		const characters = sanitizedValue.split("")

		characters.forEach((character, i) => {
			const newIndex = index + i
			if (newIndex < inputRefs.current.length) {
				setCode((prevCode) => {
					const newCode = prevCode.split("")
					newCode[newIndex] = character
					return newCode.join("")
				})
			}
		})

		const lastFilledIndex = index + characters.length - 1
		if (lastFilledIndex >= inputRefs.current.length) {
			inputRefs.current[
				inputRefs.current.length - 1
			]?.focus()
		} else {
			inputRefs.current[lastFilledIndex + 1]?.focus()
		}
	}

	const handleKeyDown = (
		index: number,
		e: React.KeyboardEvent<HTMLInputElement>,
	) => {
		if (e.key === "ArrowLeft") {
			e.preventDefault()
			if (index > 0) {
				inputRefs.current[index - 1]?.focus()
			}
		} else if (e.key === "ArrowRight") {
			e.preventDefault()
			if (index < inputRefs.current.length - 1) {
				inputRefs.current[index + 1]?.focus()
			}
		} else if (
			e.key === "Backspace" &&
			index >= 0 &&
			inputRefs.current[index]?.selectionStart === 0
		) {
			e.preventDefault()
			setCode((prevCode) => {
				const newCode = prevCode.split("")
				newCode[index - 1] = ""
				const updatedCode = newCode.join("")

				inputRefs.current[index - 1]?.focus()

				return updatedCode
			})
		} else if (
			e.key === "Backspace" &&
			index >= 0 &&
			inputRefs.current[index]?.selectionStart === 1
		) {
			e.preventDefault()
			setCode((prevCode) => {
				const newCode = prevCode.split("")
				newCode[index] = ""
				const updatedCode = newCode.join("")

				inputRefs.current[index - 1]?.focus()

				return updatedCode
			})
		}
	}

	return (
		<form
			className={clsx(
				"absolute right-0 top-0 tablet:right-[calc(50%-185px)]",
				"h-full w-full tablet:w-[375px]",
				"flex items-center justify-center",
				"transition-all duration-500",
				page === "verify"
					? ["opacity-100 delay-200", "translate-y-0"]
					: [
							"pointer-events-none opacity-0",
							sectionOrder[page] > sectionOrder.verify
								? "translate-y-24"
								: "-translate-y-24",
					  ],
			)}
			onSubmit={(e) => {
				e.preventDefault()
				handleSignUp(code)
			}}>
			<div className="flex w-full flex-col items-stretch gap-3 px-4 tablet:relative tablet:w-[375px] tablet:px-0">
				<h3 className="text-center text-[20px] font-700 text-blue-800">
					{t("txt_confirm_your_email")}
				</h3>
				<div className="mb-4 text-center text-[18px] font-500 text-blue-600">
					<p>{t("txt_enter_verification_code")}</p>
					<p>{email}</p>
				</div>
				<button
					type="button"
					className={clsx(
						"absolute left-2 top-2 tablet:-top-16 tablet:left-0",
						"bg-blue-200 hover:bg-blue-100",
						"transition-colors duration-200",
						"h-8 w-8 rounded-full px-3",
					)}
					onClick={() => setPage("signup")}>
					<svg
						width="6"
						height="12"
						viewBox="0 0 6 12"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M5.12969 10.2594L0.871094 6.00078L5.12969 1.74219"
							stroke="var(--color-blue-900)"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>

				<div className="flex flex-col gap-1">
					<div className="flex flex-row items-center justify-center gap-[9px]">
						{[...Array(6)].map((_, index) => (
							<input
								key={index}
								ref={(ref) =>
									(inputRefs.current[index] = ref)
								}
								type="text"
								pattern="[0-9]*"
								value={code[index] || ""}
								onChange={(e) =>
									handleChange(index, e.target.value)
								}
								onKeyDown={(e) => handleKeyDown(index, e)}
								className={clsx(
									"border-[1.5px]",
									"bg-color-cell outline-none",
									"rounded-[8px]",
									"h-[50px] w-[50px] placeholder-color-placeholder",
									"no-autofill text-center transition-all duration-200",
									hasError && code.length === 6
										? "border-color-error"
										: "border-color-separator focus:border-blue-500",
								)}
							/>
						))}
					</div>
					<p
						className={clsx(
							" px-4 text-[14px] font-500 text-color-error transition-opacity",
							(!hasError || code.length !== 6) &&
								"pointer-events-none select-none opacity-0",
						)}>
						{t("txt_code_doesnt_exist")}
					</p>
				</div>

				<Button
					type="submit"
					className="atablet:mt-[150px] w-full"
					text="lbl_next"
					loading={requested}
					disabled={
						requested || notCurrent || code.length !== 6
					}
					primary
				/>
				<p className="text-center text-[15px] font-400 text-blue-800">
					{t("txt_didnt_receive_email")}
				</p>
				<div className="flex flex-col">
					<p className="text-center text-[16px] font-500 text-blue-600">
						{timerDisplay}
					</p>
					<button
						onClick={(e) => {
							e.preventDefault()
							handleResend()
						}}
						className={clsx(
							"text-center text-[16px] font-500",
							timer === 0
								? "text-primary-500"
								: "text-primary-500/30 pointer-events-none cursor-not-allowed",
						)}>
						{t("txt_resend_code")}
					</button>
				</div>
			</div>
		</form>
	)
}

export function UsernameSection(props: SignUpSectionProps) {
	const {
		page,
		redirectUrl,
		setPage,
		setQuiz,
		requested,
		setRequested,
	} = props

	const { t } = useTranslation()
	const { userInfo, setUserInfo } = useAuth()
	const logAdjust = useAdjustLogger()

	const [username, setUsername] = useState("@")
	const [fullName, setFullName] = useState("")
	const [usernameError, setUsernameError] = useState(false)
	const months: string[] = [
		"txt_january",
		"txt_february",
		"txt_march",
		"txt_april",
		"txt_may",
		"txt_june",
		"txt_july",
		"txt_august",
		"txt_september",
		"txt_october",
		"txt_november",
		"txt_december",
	]
	const currentYear = new Date().getFullYear()
	const years: number[] = Array.from(
		{ length: 100 },
		(_, i) => currentYear - i - 13,
	)

	const [selectedDay, setSelectedDay] = useState<
		number | null
	>(null)
	const [selectedMonth, setSelectedMonth] = useState<
		number | null
	>(null)
	const [selectedYear, setSelectedYear] = useState<
		number | null
	>(null)
	const [days, setDays] = useState<number[]>(
		Array.from({ length: 31 }, (_, i) => i + 1),
	)

	const isDateNull =
		selectedMonth === null ||
		selectedYear === null ||
		selectedDay === null

	const notCurrent = page !== "username"

	const canSubmit = fullName.trim().length !== 0

	const { notify } = useContext(NotificationContext)

	const router = useRouter()

	const updateDays = useCallback(
		(
			month: number | null,
			year: number | null,
		): number[] => {
			if (month === null || year === null) return []
			const daysInMonth = new Date(year, month, 0).getDate()
			const newDays = Array.from(
				{ length: daysInMonth },
				(_, i) => i + 1,
			)

			if (
				selectedDay !== null &&
				selectedDay <= daysInMonth
			) {
				setSelectedDay(selectedDay)
			} else if (
				selectedDay !== null &&
				selectedDay > daysInMonth
			) {
				setSelectedDay(daysInMonth)
			}

			setDays(newDays)
			return newDays
		},
		[selectedDay],
	)

	async function handleSignUp(
		e: FormEvent<HTMLFormElement>,
	) {
		e.preventDefault()
		if (!canSubmit || isDateNull) {
			return
		}

		setRequested(true)

		const birthdate = new Date(
			selectedYear,
			selectedMonth - 1,
			selectedDay,
		)

		const currentDate = new Date()
		let age =
			currentDate.getFullYear() - birthdate.getFullYear()

		if (
			currentDate.getMonth() < birthdate.getMonth() ||
			(currentDate.getMonth() === birthdate.getMonth() &&
				currentDate.getDate() < birthdate.getDate())
		) {
			age--
		}

		if (age < 13) {
			notify(
				<ErrorMessage>
					{t("txt_age_under_13")}
				</ErrorMessage>,
			)
			setRequested(false)
			return
		}

		try {
			const dateNumber = birthdate.getTime()
			await axios
				.post("/api/user-birthdate", {
					birthdate: dateNumber,
				})
				.then((res) => res.data)

			await axios
				.post("/api/user-fullname", {
					full_name: fullName,
				})
				.then((res) => res.data)

			const randomString = Math.random()
				.toString(36)
				.substring(2, 14)
			const randomUsername = `user${randomString}`
			const match = username.trim().match(/@(\w+)/)
			const usernameToSend = match
				? match[1]
				: randomUsername

			const isAvailable = await axios
				.post<boolean>("/api/user-username", {
					username:
						username.trim() !== "@"
							? usernameToSend.replace(/ /g, "_")
							: randomUsername,
				})
				.then((res) => res.data)

			if (!isAvailable) {
				setUsernameError(true)
				setRequested(false)
				return
			}

			forceRefreshToken()
				.then((userInfo) => setUserInfo(userInfo))
				.catch((error) =>
					console.error("Could not get new token", error),
				)

			const quizParams = {
				uid: userInfo.userId,
			}
			const quiz = await axios
				.post<Quiz | null>("/api/quiz", quizParams)
				.then((res) => res.data)
			if (quiz) {
				setQuiz(quiz)
				setPage("quiz")
				setRequested(false)
				return
			}

			let url = "/"

			if (typeof redirectUrl === "string") {
				url = redirectUrl
			}

			logAdjust?.logEvent("w_onboarding_success")

			await router.push(url)
		} catch (error) {
			console.error(error)
			notify(
				<ErrorMessage>
					{t("txt_something_wrong")}
				</ErrorMessage>,
			)
		}

		setRequested(false)
	}

	return (
		<form
			className={clsx(
				"absolute right-0 top-0 tablet:right-[calc(50%-185px)]",
				"h-full w-full tablet:w-[375px]",
				"flex items-center justify-center",
				"transition-all duration-500",
				page === "username"
					? ["opacity-100 delay-200", "translate-y-0"]
					: [
							"pointer-events-none opacity-0",
							sectionOrder[page] > sectionOrder.username
								? "translate-y-24"
								: "-translate-y-24",
					  ],
			)}
			onSubmit={handleSignUp}>
			<div className="flex w-full flex-col items-stretch gap-3 px-4 tablet:relative tablet:w-[375px] tablet:px-0">
				<div className="mb-4 flex flex-col gap-2">
					<h3 className="text-center text-[24px] font-700 text-blue-800">
						{t("txt_account_information")}
					</h3>
				</div>

				<div className="flex flex-col">
					<BorderInput
						name="username"
						type="text"
						placeholder={"@" + t("lbl_username")}
						className="!bg-color-background"
						hasErrors={usernameError}
						value={username.trim() === "@" ? "" : username}
						disabled={requested || notCurrent}
						onChange={function (event) {
							setUsernameError(false)
							const newValue = event.currentTarget.value
							if (newValue === "") {
								setUsername("@")
							} else if (
								newValue.startsWith("@") &&
								newValue.length === 1
							) {
								setUsername("@")
							} else {
								setUsername(
									newValue.startsWith("@")
										? newValue
										: `@${newValue}`,
								)
							}
						}}
					/>

					{usernameError && (
						<span className="text-[14px] font-500 text-color-error">
							{t("txt_username_used")}
						</span>
					)}
				</div>

				<BorderInput
					name="full_name"
					type="text"
					placeholder={t("lbl_full_name")}
					className="!bg-color-background"
					value={fullName}
					disabled={requested || notCurrent}
					onChange={function (event) {
						setFullName(event.currentTarget.value)
					}}
				/>

				<div className="flex w-full flex-row gap-2">
					{/* Year Dropdown */}
					<DropDown
						className={clsx(
							"transition-opacity",
							requested &&
								"pointer-events-none cursor-crosshair opacity-30",
						)}
						trigger={
							<div
								className={clsx(
									"flex flex-row items-center justify-between px-5",
									"h-11 w-[100px] rounded-[8px] bg-color-background tablet:h-[50px]",
									"border-[1.5px] border-color-separator focus:border-blue-500",
								)}>
								<p
									className={clsx(
										"text-[16px] font-400",
										selectedYear === null
											? "text-color-placeholder"
											: "text-blue-700",
									)}>
									{selectedYear !== null
										? selectedYear
										: t("txt_year")}
								</p>
								<div
									className={clsx(
										"flex -rotate-90 items-center justify-center",
										"transition-all [--icon-color:var(--color-placeholder)] group-hover:-rotate-[270deg]",
									)}>
									<ButtonArrowIcon />
								</div>
							</div>
						}>
						<div className="absolute z-10 w-[100px] pt-2">
							<div className="no-scrollbar max-h-[200px] w-full overflow-y-scroll rounded-[10px] bg-color-popup-cell px-[10px] py-[10px] [box-shadow:0px_0px_10px_rgba(0,0,0,0.13)]">
								<div className="flex w-full flex-col gap-1">
									{years.map((year) => (
										<div
											key={year}
											className={clsx(
												"flex w-full cursor-pointer items-center justify-center rounded-[8px] py-2 text-center text-[14px] font-500 text-blue-800 transition-all",
												selectedYear === year
													? "bg-blue-100 hover:bg-blue-100"
													: "hover:bg-color-background",
											)}
											onClick={() => {
												setSelectedYear(year)
												updateDays(selectedMonth, year)
											}}>
											{year}
										</div>
									))}
								</div>
							</div>
						</div>
					</DropDown>

					{/* Month Dropdown */}
					<DropDown
						className={clsx(
							"flex-1 transition-opacity",
							requested &&
								"pointer-events-none cursor-crosshair opacity-30",
						)}
						triggerClassName="w-full tablet:w-[157px]"
						trigger={
							<div
								className={clsx(
									"flex flex-row items-center justify-between px-5",
									"h-11 w-full rounded-[8px] bg-color-background tablet:h-[50px] tablet:w-[157px]",
									"border-[1.5px] border-color-separator focus:border-blue-500",
								)}>
								<p
									className={clsx(
										"text-[16px] font-400",
										selectedMonth === null
											? "text-color-placeholder"
											: "text-blue-700",
									)}>
									{selectedMonth !== null
										? t(months[selectedMonth - 1])
										: t("txt_month")}
								</p>
								<div
									className={clsx(
										"flex -rotate-90 items-center justify-center",
										"transition-all [--icon-color:var(--color-placeholder)] group-hover:-rotate-[270deg]",
									)}>
									<ButtonArrowIcon />
								</div>
							</div>
						}>
						<div className="absolute z-10 w-[130px] pt-2 tablet:w-[157px]">
							<div className="no-scrollbar max-h-[200px] w-full overflow-y-scroll rounded-[10px] bg-color-popup-cell px-[10px] py-[10px] [box-shadow:0px_0px_10px_rgba(0,0,0,0.13)]">
								<div className="flex w-full flex-col gap-1">
									{months.map((month, index) => (
										<div
											key={index}
											className={clsx(
												"flex w-full cursor-pointer items-center rounded-[8px] py-2 pl-4 text-[14px] font-500 text-blue-800 transition-all",
												selectedMonth === index + 1
													? "bg-blue-100 hover:bg-blue-100"
													: "hover:bg-color-background",
											)}
											onClick={() => {
												setSelectedMonth(index + 1)
												updateDays(index + 1, selectedYear)
											}}>
											{t(month)}
										</div>
									))}
								</div>
							</div>
						</div>
					</DropDown>

					{/* Day Dropdown */}
					<DropDown
						className={clsx(
							"transition-opacity",
							requested &&
								"pointer-events-none cursor-crosshair opacity-30",
						)}
						trigger={
							<div
								className={clsx(
									"flex flex-row items-center justify-between px-5",
									"h-11 w-[100px] rounded-[8px] bg-color-background transition-opacity tablet:h-[50px]",
									"border-[1.5px] border-color-separator focus:border-blue-500",
								)}>
								<p
									className={clsx(
										"text-[16px] font-400",
										selectedDay === null
											? "text-color-placeholder"
											: "text-blue-700",
									)}>
									{selectedDay !== null
										? selectedDay
										: t("txt_day")}
								</p>
								<div
									className={clsx(
										"flex -rotate-90 items-center justify-center",
										"transition-all [--icon-color:var(--color-placeholder)] group-hover:-rotate-[270deg]",
									)}>
									<ButtonArrowIcon />
								</div>
							</div>
						}>
						<div className="absolute z-10 w-[100px] pt-2">
							<div className="no-scrollbar max-h-[200px] w-full overflow-y-scroll rounded-[10px] bg-color-popup-cell px-[10px] py-[10px] [box-shadow:0px_0px_10px_rgba(0,0,0,0.13)]">
								<div className="flex w-full flex-col gap-1">
									{days.map((day) => (
										<div
											key={day}
											className={clsx(
												"flex w-full cursor-pointer items-center justify-center rounded-[8px] py-2 text-center text-[14px] font-500 text-blue-800 transition-all",
												selectedDay === day
													? "bg-blue-100 hover:bg-blue-100"
													: "hover:bg-color-background",
											)}
											onClick={() => setSelectedDay(day)}>
											{day}
										</div>
									))}
								</div>
							</div>
						</div>
					</DropDown>
				</div>

				<Button
					type="submit"
					className="w-full disabled:!bg-blue-200 disabled:!text-blue-400 disabled:hover:!bg-blue-200 tablet:mt-[50px]"
					text="lbl_create"
					loading={requested}
					disabled={
						requested ||
						notCurrent ||
						!canSubmit ||
						isDateNull
					}
					primary
				/>
			</div>
		</form>
	)
}

export function QuizSection(props: QuizSectionProps) {
	const { page, quiz, setPage, requested, setRequested } =
		props

	const { t } = useTranslation()
	const logAdjust = useAdjustLogger()

	const { notify } = useContext(NotificationContext)

	const { userInfo } = useAuth()

	const notCurrent = page !== "quiz"

	const [currentQuestionIndex, setCurrentQuestionIndex] =
		useState(0)
	const [answers, setAnswers] = useState<
		Record<string, string>
	>({})
	const [progress, setProgress] = useState(0)
	const [
		visitedQuestionIndices,
		setVisitedQuestionIndices,
	] = useState<number[]>([])
	const [open, setOpen] = useState(false)
	const [options, setOptions] = useState<OptionType | null>(
		null,
	)

	const currentOptions =
		quiz.data.pages[currentQuestionIndex].options

	const findOptions = currentOptions.map(
		(option) => !!answers[option.id],
	)

	const isCurrentAnswered = answers[
		quiz.data.pages[currentQuestionIndex].id
	]
		? true
		: findOptions.every(Boolean)

	const handleSelectAnswer = async (
		questionId: string,
		optionId: string,
	) => {
		setAnswers({ ...answers, [questionId]: optionId })
		const nextPage = getNextPage(optionId)
		if (nextPage !== null) {
			setProgress(quiz.data.pages[nextPage].progress ?? 0)
			setCurrentQuestionIndex(nextPage)
			setVisitedQuestionIndices([
				...visitedQuestionIndices,
				currentQuestionIndex,
			])
		} else {
			setProgress(100)
			await submitQuiz({
				...answers,
				[questionId]: optionId,
			})
			setPage("plan")
		}
	}

	const handleInputAnswer = (
		questionId: string,
		value: string,
	) => {
		setAnswers({ ...answers, [questionId]: value })
	}

	const handleButtonClick = async () => {
		if (!isCurrentAnswered) return

		const nextPage = getNextPage()
		if (nextPage !== null) {
			setProgress(quiz.data.pages[nextPage].progress ?? 0)
			setCurrentQuestionIndex(nextPage)
			setVisitedQuestionIndices([
				...visitedQuestionIndices,
				currentQuestionIndex,
			])
		} else {
			setProgress(100)
			await submitQuiz(answers)
			setPage("plan")
		}
	}

	const getNextPage = (answer?: string) => {
		const currentPage =
			quiz.data.pages[currentQuestionIndex]
		const selectedOption = currentPage.options.find(
			(option) => option.id === answer,
		)

		if (
			selectedOption?.type === "select" &&
			selectedOption.next_page
		) {
			return quiz.data.pages.findIndex(
				(page) => page.id === selectedOption.next_page,
			)
		} else {
			const nextPageId = currentPage.next_page
			if (nextPageId) {
				return quiz.data.pages.findIndex(
					(page) => page.id === nextPageId,
				)
			} else {
				return null
			}
		}
	}

	const handlePrevPage = () => {
		const prevQuestionIndex = visitedQuestionIndices.pop()
		if (prevQuestionIndex !== undefined) {
			const prevQuestion =
				quiz.data.pages[currentQuestionIndex]
			const prevQuestionId = prevQuestion.id
			const updatedAnswers = { ...answers }

			delete updatedAnswers[prevQuestionId]

			if (prevQuestion.options) {
				prevQuestion.options.forEach((option) => {
					if (updatedAnswers[option.id]) {
						delete updatedAnswers[option.id]
					}
				})
			}

			setAnswers(updatedAnswers)
			setProgress(
				quiz.data.pages[prevQuestionIndex].progress ?? 0,
			)
			setCurrentQuestionIndex(prevQuestionIndex)
		}
	}

	async function submitQuiz(
		newAnsweres: Record<string, string>,
		skip?: boolean,
	) {
		setRequested(true)
		const payload = {
			quiz_id: quiz.quiz_id,
			uid: userInfo.userId,
			answers: newAnsweres,
		}

		try {
			await axios.post("/api/quiz-submit", payload)
			logAdjust?.logEvent(
				skip ? "w_dp_onboarding_skip" : "w_did_finish_quiz",
			)
		} catch {
			notify(
				<ErrorMessage>
					{t("txt_something_wrong")}
				</ErrorMessage>,
			)
		}
		setRequested(false)
	}

	return (
		<div
			className={clsx(
				"absolute right-0 top-0 tablet:right-[calc(50%-250px)]",
				"h-[100svh] w-full tablet:h-full tablet:w-[500px]",
				"flex items-center justify-center",
				"transition-all duration-500",
				page === "quiz"
					? ["opacity-100 delay-200", "translate-y-0"]
					: [
							"pointer-events-none opacity-0",
							sectionOrder[page] > sectionOrder.quiz
								? "translate-y-24"
								: "-translate-x-32",
					  ],
			)}>
			<div
				className={clsx(
					"fixed left-0 top-0 flex h-[100svh] w-[100vw] items-end justify-start bg-color-popup tablet:hidden",
					!open && "pointer-events-none opacity-0",
					"z-[120] transition-opacity",
				)}
				id="find-more-popup"
				onClick={(event) => {
					if (event.target instanceof Element) {
						if (
							event.target.id === event.currentTarget.id
						) {
							setOpen(false)
						}
					}
				}}>
				<div
					className={clsx(
						"relative h-[50svh] w-full rounded-t-[12px] bg-color-popup-cell pt-3 transition-all",
						open ? "translate-y-0" : "translate-y-full",
					)}>
					<div className="no-scrollbar flex h-full w-full flex-col gap-[8px] overflow-y-auto rounded-[13px] bg-color-popup-cell px-[10px] py-[14px]">
						{options &&
							options.map((item) => (
								<button
									key={item.id}
									onClick={(e) => {
										e.preventDefault()
										handleSelectAnswer(
											quiz.data.pages[currentQuestionIndex]
												.id,
											item.id,
										)
										setOpen(false)
									}}
									className={clsx(
										"flex w-full flex-row items-center rounded-[8px] px-4 py-3",
										answers[
											quiz.data.pages[currentQuestionIndex]
												.id
										] === item.id && "bg-primary-100",
									)}>
									{item.title}
								</button>
							))}
					</div>
				</div>
			</div>

			<div className="relative h-full w-full overflow-auto px-6 tablet:overflow-visible tablet:px-0">
				<div
					className={clsx(
						"no-scrollbar absolute left-0 top-0 flex h-full w-full flex-col items-center justify-start gap-3 px-6 transition-all tablet:left-[50px] tablet:w-[400px] tablet:px-0",
					)}>
					<div className="mb-4 mt-11 flex w-full flex-col items-center justify-center gap-2 text-center">
						<h3 className="text-center text-[22px] font-600 text-blue-800">
							{t("txt_welcome")}
						</h3>
						<p className="text-center text-[16px] font-400 text-blue-700">
							{t("txt_tell_us")}
						</p>
					</div>

					<div className="relative mb-[20px] h-[6px] w-full overflow-hidden rounded-[40px] bg-blue-200 tablet:w-[375px]">
						<div
							style={{
								width: `${progress}%`,
							}}
							className="absolute left-0 top-0 h-[6px] bg-primary-500 transition-all"></div>
					</div>

					<div
						className={clsx(
							"relative h-full w-full",
							requested && "pointer-events-none opacity-40",
						)}>
						<div
							className={clsx(
								"fixed bottom-0 left-0 w-full bg-color-cell px-6 py-5 tablet:absolute tablet:-left-10 tablet:top-0 tablet:w-auto tablet:bg-[transparent] tablet:p-0",
								"z-[1] flex flex-row items-center justify-start tablet:items-start",
							)}>
							<button
								type="button"
								className={clsx(
									"bg-blue-200 hover:bg-blue-100",
									"flex items-center justify-center transition-all duration-200",
									"h-10 w-10 rounded-full px-3",
									currentQuestionIndex === 0 &&
										"pointer-events-none opacity-0",
								)}
								onClick={handlePrevPage}>
								<svg
									width="6"
									height="12"
									viewBox="0 0 6 12"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M5.12969 10.2594L0.871094 6.00078L5.12969 1.74219"
										stroke="var(--color-blue-900)"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</button>

							<div className="flex flex-1 items-center justify-center tablet:hidden">
								<button
									type="button"
									onClick={async (e) => {
										e.preventDefault()
										await submitQuiz({}, true)
										setPage("plan")
									}}
									disabled={notCurrent || requested}
									className="-translate-x-5 text-[16px] font-400 text-blue-400 tablet:hidden">
									{t("txt_skip_now")}
								</button>
							</div>
						</div>

						<div className="-right-[60px] bottom-4 z-[1] hidden h-[25px] w-auto tablet:absolute tablet:flex">
							<button
								type="button"
								onClick={async (e) => {
									e.preventDefault()
									await submitQuiz({}, true)
									setPage("plan")
								}}
								disabled={notCurrent || requested}
								className="text-[16px] font-400 text-blue-400">
								{t("txt_skip_now")}
							</button>
						</div>

						{quiz.data.pages.map((question, index) => {
							const options = question.options

							const findOptions = options.map(
								(option) => !!answers[option.id],
							)

							const isAnswered = findOptions.every(Boolean)

							type PositionInQuiz =
								| "not_answered"
								| "current"
								| "answered"
							function getPositionInQuiz(
								currentIndex: number,
								answersLength: number,
							): PositionInQuiz {
								if (answersLength === currentIndex) {
									return "current"
								}

								if (answers[question.id] || isAnswered) {
									return "answered"
								}

								return "not_answered"
							}

							const position = getPositionInQuiz(
								index,
								currentQuestionIndex,
							)

							return (
								<form
									key={question.id}
									onSubmit={(e) => {
										e.preventDefault()
										handleButtonClick()
									}}
									className={clsx(
										"absolute left-0 top-0 flex h-full w-full flex-col items-center gap-3 transition-all duration-300",
										position === "not_answered" &&
											"pointer-events-none translate-x-32 opacity-0",
										position === "current" &&
											"translate-x-0 opacity-100",
										position === "answered" &&
											" pointer-events-none -translate-x-32 opacity-0",
									)}>
									<div className="flex w-full flex-col items-center tablet:w-[375px]">
										<p className="text-center text-[18px] font-600 text-blue-800">
											{question.title}
										</p>
									</div>
									<div
										className={clsx(
											"no-scrollbar flex min-h-[200px] w-full flex-col items-center gap-[10px] overflow-y-scroll pb-[90px] tablet:min-h-0 tablet:overflow-auto tablet:p-0",
										)}>
										{question.options.map((option) => {
											switch (option.type) {
												case "select":
													return (
														<button
															key={option.id}
															className={clsx(
																"group flex h-12 w-full shrink-0 flex-row items-center justify-between rounded-[8px] px-4 tablet:w-[375px]",
																answers[question.id] ===
																	option.id
																	? "bg-primary-100"
																	: "bg-blue-100",
															)}
															onClick={(e) => {
																e.preventDefault()
																handleSelectAnswer(
																	question.id,
																	option.id,
																)
															}}>
															<p className="text-[16px] font-500 text-blue-800">
																{option.title}
															</p>
															<div className="flex items-center justify-center transition-all [--icon-color:var(--color-blue-500)] desktop:-translate-x-1 desktop:opacity-0 desktop:group-hover:translate-x-0 desktop:group-hover:opacity-100">
																<RightArrow />
															</div>
														</button>
													)
												case "text":
													return (
														<BorderInput
															key={option.id}
															placeholder={option.hint}
															type={option.input_type}
															className="w-full !bg-color-background tablet:w-[375px]"
															value={
																answers[option.id] ?? ""
															}
															onChange={(e) => {
																handleInputAnswer(
																	option.id,
																	e.currentTarget.value,
																)
															}}
														/>
													)
												case "find":
													return (
														<>
															<DropDown
																triggerClassName="w-full hidden tablet:block tablet:w-[375px]"
																className="hidden w-full tablet:block tablet:w-[375px]"
																key={option.id}
																trigger={
																	<div
																		className={clsx(
																			"hidden h-12 w-full items-center justify-between rounded-[8px] px-4 tablet:flex tablet:w-[375px]",
																			option.options.find(
																				(a) =>
																					a.id ===
																					answers[
																						question.id
																					],
																			)
																				? "bg-primary-500/20"
																				: "border border-color-separator bg-color-cell",
																		)}>
																		<p className="text-[16px] font-500 text-blue-800">
																			{option.options.find(
																				(a) =>
																					a.id ===
																					answers[
																						question.id
																					],
																			)?.title ??
																				option.hint}
																		</p>
																		<div
																			className={clsx(
																				"flex -rotate-90 items-center justify-center",
																				"transition-all [--icon-color:var(--color-placeholder)] group-hover:-rotate-[270deg]",
																			)}>
																			<ButtonArrowIcon />
																		</div>
																	</div>
																}>
																<div className="absolute z-10 w-[375px] pt-2">
																	<div className="no-scrollbar max-h-[150px] w-full overflow-y-scroll rounded-[10px] border border-color-separator bg-blue-200 [box-shadow:0px_0px_10px_rgba(0,0,0,0.13)]">
																		<div className="flex w-full flex-col">
																			{option.options.map(
																				(item) => (
																					<div
																						key={item.id}
																						onClick={(
																							e,
																						) => {
																							e.preventDefault()
																							handleSelectAnswer(
																								question.id,
																								item.id,
																							)
																						}}
																						className={clsx(
																							"flex w-full cursor-pointer items-center justify-start px-4 py-2 text-start text-[16px] font-500 text-blue-800 transition-all",
																							answers[
																								question.id
																							] === item.id
																								? "bg-primary-500/20"
																								: "bg-blue-200 hover:bg-blue-300",
																						)}>
																						{item.title}
																					</div>
																				),
																			)}
																		</div>
																	</div>
																</div>
															</DropDown>

															<button
																onClick={(e) => {
																	e.preventDefault()
																	setOptions(option.options)
																	setOpen(true)
																}}
																type="button"
																className={clsx(
																	"flex h-12 w-full shrink-0 items-center justify-between rounded-[8px] px-4 tablet:hidden",
																	option.options.find(
																		(a) =>
																			a.id ===
																			answers[question.id],
																	)
																		? "bg-primary-500/20"
																		: "border border-color-separator bg-color-cell",
																)}>
																<p className="text-[16px] font-500 text-blue-800">
																	{option.options.find(
																		(a) =>
																			a.id ===
																			answers[question.id],
																	)?.title ?? option.hint}
																</p>
																<div
																	className={clsx(
																		"flex -rotate-90 items-center justify-center",
																		"transition-all [--icon-color:var(--color-placeholder)] group-hover:-rotate-[270deg]",
																	)}>
																	<ButtonArrowIcon />
																</div>
															</button>
														</>
													)
											}
										})}

										{question.button_text !== undefined && (
											<Button
												type="submit"
												primary
												className="w-full tablet:w-[375px]"
												disabled={
													notCurrent ||
													requested ||
													!isAnswered
												}
												text={question.button_text}
											/>
										)}
									</div>
								</form>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
}

interface PlanSectionProps {
	setPage: (page: LoginPage) => void
	page: LoginPage
	redirectUrl: string | string[] | null
	setMetaTitle: (meta: MetaTitleTypes) => void
}

function PlanSection(props: PlanSectionProps) {
	const { setPage, page, setMetaTitle, redirectUrl } = props

	const { t } = useTranslation()

	const router = useRouter()
	const { userInfo } = useAuth()

	const prices = useQuery({
		queryKey: ["offering-prices"],
		queryFn: fetchOfferingPrices(),
		staleTime: Infinity,
		cacheTime: Infinity,
	})

	const { packId: currentPackId } = getCurrentPackId(
		userInfo.entitlements,
	)

	const [isYearly, setIsYearly] = useState(true)

	const { notify } = useContext(NotificationContext)

	async function handlePurchaseClick(packId: string) {
		if (!userInfo.isAnonymous) {
			fetchPaymentLink(packId)
		} else {
			setPage("signin")
			setMetaTitle("txt_login_title")
		}
	}

	async function fetchPaymentLink(packId: string) {
		try {
			const sessionId = v4()
			const payload: PaymentStripeUrlRequest = {
				location:
					typeof redirectUrl === "string"
						? redirectUrl
						: "/",
				period: isYearly ? "yearly" : "monthly",
				adId: await getAdId(),
				sessionId,
				offering: packId,
			}

			const { url, currency, value } = await axios
				.post<PaymentStripeUrlResponse>(
					"/api/payment-stripe-url",
					payload,
				)
				.then((res) => res.data)

			logAdjust?.logEvent("initiate_checkout")
			logAdjust?.logEvent("w_onboarding_success")

			const paymentData: PaymentSessionData = {
				currency,
				value,
				session: sessionId,
			}

			localStorage.setItem(
				PAYMENT_DATA_KEY,
				JSON.stringify(paymentData),
			)

			router.push(url)
		} catch (error) {
			console.error(error)
			notify(
				<ErrorMessage>
					{t("txt_could_not_purchase_now")}
				</ErrorMessage>,
			)
		}
		window.blur()
	}

	async function handleSkip() {
		let url = "/"

		if (typeof redirectUrl === "string") {
			url = redirectUrl
		}

		logAdjust?.logEvent("w_onboarding_success")
		await router.push(url)
	}

	const logAdjust = useAdjustLogger()

	const yearlyPricePro =
		prices.data?.plans.basic.variants[0].periods.find(
			(a) => a.period === "yearly",
		)?.price || 0
	const yearlyPriceDividedPro = yearlyPricePro / 12
	const yearlyPriceFormattedPro = Math.floor(
		yearlyPriceDividedPro,
	)

	const yearlyPriceAdvanced =
		prices.data?.plans.advanced.variants[0].periods.find(
			(a) => a.period === "yearly",
		)?.price || 0
	const yearlyPriceDividedAdvanced =
		yearlyPriceAdvanced / 12
	const yearlyPriceFormattedAdvanced = Math.floor(
		yearlyPriceDividedAdvanced,
	)

	return (
		<div
			className={clsx(
				"no-scrollbar absolute left-0 top-0 overflow-auto py-11 transition-all",
				"flex h-full w-full flex-col items-center justify-start gap-3",
				"tablet:pt-0",
				page === "plan"
					? ["opacity-100 delay-200", "translate-y-0"]
					: [
							"pointer-events-none opacity-0",
							sectionOrder[page] > sectionOrder.plan
								? "translate-x-32"
								: "-translate-x-32",
					  ],
			)}>
			<p className="text-[22px] font-700 text-blue-800 tablet:pt-10 tablet:text-[20px] desktop:pt-0">
				{t("txt_unlock_all_features")}
			</p>
			<div className=" flex gap-[10px]">
				<span
					className={clsx(
						isYearly ? "text-blue-500" : "text-blue-900",
						"text-[18px] font-500 transition-colors",
					)}>
					{t("txt_monthly")}
				</span>
				<SquareSwitch
					onChange={setIsYearly}
					enabled={isYearly}
				/>
				<span
					className={clsx(
						isYearly ? "text-blue-900" : "text-blue-500",
						"text-[18px] font-500 transition-colors",
					)}>
					{t("txt_yearly")}
				</span>
				<span
					className={clsx(
						"text-[18px] font-500 transition-colors",
						isYearly ? "text-blue-900" : "text-blue-500",
					)}>
					{t("fs_save_percent", {
						percent: String(plans.save_percent),
					})}
				</span>
			</div>

			<div className="mt-2 flex w-full flex-col items-center justify-center gap-4 px-4 tablet:px-5 desktop:px-12">
				{/* Free Plan */}
				{/* <div
          className={clsx(
            "flex w-full flex-col overflow-hidden rounded-[8px]",
            "border border-color-separator tablet:flex-row",
            "[box-shadow:0px_5px_12px_0px_rgba(183,183,183,0.2)] dark:[box-shadow:unset]",
          )}>
          <div className="bg-color-surface-cell flex w-full flex-1 flex-col justify-between px-4 pb-4 pt-3">
            <div className="flex flex-col">
              <p className="text-[24px] font-600 text-blue-900">
                {t("txt_just_try")}
              </p>
              <p className="font-300 text-[14px] text-blue-600">
                {t("txt_free_subtitle")}
              </p>
              <p className="mt-1 text-[26px] font-800 uppercase text-blue-800">
                {t("txt_free")}
              </p>
            </div>
            <div className=" mt-4 flex w-full">
              <Button
                outlineSecondary
                onClick={() => setPage("generate")}
                className="w-full"
                text="txt_get_started"></Button>
            </div>
          </div>
          <div className="h-[1px] w-full bg-blue-100 tablet:h-full tablet:w-[1px] " />
          <div className="flex flex-col gap-2 bg-blue-50 p-4 tablet:w-[280px]">
            {plans.plans.free.points.map((point) => {
              return (
                <div
                  className="flex items-center gap-[10px] "
                  key={point}>
                  <CustomThemedResource
                    format="svg"
                    source="/pricing/check"
                  />
                  <span className="text-[14px] font-400 text-blue-800">
                    {point}
                  </span>
                </div>
              )
            })}
          </div>
        </div> */}

				{/*PRIME */}
				<div
					className={clsx(
						"flex w-full flex-col overflow-hidden rounded-[8px] border-2 border-color-separator",
						"tablet:flex-row",
					)}>
					<div className="flex w-full flex-1 flex-col justify-between bg-color-surface-cell px-4 pb-4 pt-3">
						<div className="flex flex-col desktop:mb-2">
							<p className="text-[24px] font-600 text-blue-900">
								{"Pro + AI"}
							</p>
							<p className="font-300 text-[14px] text-blue-600">
								{"For pro video creation"}
							</p>
							<div className="relative mt-3 h-[31px] w-full overflow-hidden">
								<div
									className={clsx(
										"absolute inset-0",
										"flex h-[31px] w-full flex-row items-end justify-start gap-[2px]",
										isYearly && "-top-8",
										"transition-all duration-500",
									)}>
									<span
										className={clsx(
											"font-700 text-blue-900",
											"text-[26px] leading-[31px]",
										)}>
										$
										{String(
											prices.data?.plans.basic.variants[0].periods.find(
												(a) => a.period === "monthly",
											)?.price,
										).slice(0, -2)}
										.
										<small>
											{String(
												prices.data?.plans.basic.variants[0].periods.find(
													(a) => a.period === "monthly",
												)?.price,
											).slice(-2)}
										</small>
									</span>
									<span className="font-300 mb-1 text-[14px] lowercase text-blue-600">
										/{t("txt_month")}
									</span>
								</div>

								<div
									className={clsx(
										"absolute inset-0",
										"flex h-[31px] w-full flex-row items-end justify-start gap-[2px]",
										!isYearly && "top-8",
										"transition-all duration-500",
									)}>
									<span
										className={clsx(
											"font-700 text-blue-900",
											"text-[26px] leading-[31px]",
										)}>
										$
										{String(yearlyPriceFormattedPro).slice(
											0,
											-2,
										).length !== 0
											? String(
													yearlyPriceFormattedPro,
											  ).slice(0, -2)
											: 0}
										.
										<small>
											{String(
												yearlyPriceFormattedPro,
											).slice(-2)}
										</small>
									</span>
									<span className="font-300 mb-1 text-[14px] lowercase text-blue-600">
										/{t("txt_month")}*
									</span>
								</div>
							</div>
						</div>

						<div className="flex w-full">
							<GradientGrayscaleButton
								grayscale
								disabled={
									getBasicButtonText(currentPackId) ===
											"txt_current_plan"
								}
								onClick={() =>
									handlePurchaseClick(
										prices.data?.plans.basic.variants[0]
											.pack_id || "",
									)
								}
								className="flex h-[52px] w-full items-center justify-center !font-500 disabled:!text-color-inactive-text"
								text={getBasicButtonText(currentPackId)}
							/>
						</div>
					</div>
					<div className="h-[1px] w-full bg-blue-100 tablet:h-full tablet:w-[1px]" />
					<div className="flex flex-col gap-2 bg-blue-50 p-4 tablet:w-[280px]">
						{plans.plans.basic.points.map((point) => {
							return (
								<div
									className="flex items-center gap-[10px] "
									key={point}>
									<CustomThemedResource
										format="svg"
										source="/pricing/check"
									/>
									<span className="text-[14px] font-400 text-blue-800">
										{point}
									</span>
								</div>
							)
						})}
					</div>
				</div>
				{/* Enterprise */}
				<div
					className={clsx(
						"relative flex w-full flex-col rounded-[8px]",
						"tablet:flex-row",
					)}>
					<div
						style={{
							background:
								"linear-gradient(120deg, rgba(134, 54, 248, 1), rgba(240, 32, 179, 1), rgba(248, 71, 94, 1), rgba(255, 148, 33, 1) )",
						}}
						className="absolute left-[-2px] top-[-2px] h-[calc(100%+4px)] w-[calc(100%+4px)] rounded-[10px]"></div>

					<div className="z-10 flex w-full flex-1 flex-col justify-between rounded-t-[8px] bg-color-cell px-4 pb-4 pt-3 tablet:rounded-l-[8px] tablet:rounded-r-[0px]">
						<div className="flex flex-col">
							<p className="text-[24px] font-600 text-blue-900">
								{"Advanced + AI"}
							</p>
							<p className="font-300 text-[14px] text-blue-600">
								{t("txt_pro_subtitle")}
							</p>
							<div className="relative mt-3 h-[50px] w-full overflow-hidden">
								<div
									className={clsx(
										"absolute inset-0",
										"flex h-[50px] w-full flex-row items-end justify-start gap-[2px]",
										isYearly && "-top-16",
										"transition-all duration-500",
									)}>
									<span
										className={clsx(
											"font-700 text-blue-900",
											"text-[38px]",
										)}>
										$
										{String(
											prices.data?.plans.advanced.variants[0].periods.find(
												(a) => a.period === "monthly",
											)?.price,
										).slice(0, -2)}
										.
										<small>
											{String(
												prices.data?.plans.advanced.variants[0].periods.find(
													(a) => a.period === "monthly",
												)?.price,
											).slice(-2)}
										</small>
									</span>
									<span className="font-300 mb-[10px] text-[14px] lowercase text-blue-600">
										/{t("txt_month")}
									</span>
								</div>

								<div
									className={clsx(
										"absolute inset-0",
										"flex h-[50px] w-full flex-row items-end justify-start gap-[2px]",
										!isYearly && "top-16",
										"transition-all duration-500",
									)}>
									<span
										className={clsx(
											"font-700 text-blue-900",
											"text-[38px]",
										)}>
										$
										{String(
											yearlyPriceFormattedAdvanced,
										).slice(0, -2).length !== 0
											? String(
													yearlyPriceFormattedAdvanced,
											  ).slice(0, -2)
											: 0}
										.
										<small>
											{String(
												yearlyPriceFormattedAdvanced,
											).slice(-2)}
										</small>
									</span>
									<span className="font-300 mb-[10px] text-[14px] lowercase text-blue-600">
										/{t("txt_month")}*
									</span>
								</div>
							</div>
						</div>
						<div className=" mt-4 flex w-full">
							<Button
								gradient
								disabled={
									getAdvancedButtonText(currentPackId) ===
											"txt_current_plan"
								}
								onClick={() =>
									handlePurchaseClick(
										prices.data?.plans.advanced.variants[0]
											.pack_id || "",
									)
								}
								className="flex h-[52px] w-full items-center justify-center !font-500"
								text={getAdvancedButtonText(currentPackId)}
							/>
						</div>
					</div>
					<div className="z-10 h-[1px] w-full bg-blue-100 tablet:h-full tablet:w-[1px]" />
					<div className="z-10 flex flex-col gap-2 rounded-b-[8px] bg-blue-50 p-4 tablet:w-[280px] tablet:rounded-l-[0px] tablet:rounded-r-[8px]">
						{plans.plans.advanced.points.map((point) => {
							return (
								<div
									className="flex items-center gap-[10px] "
									key={point}>
									<CustomThemedResource
										format="svg"
										source="/pricing/check"
									/>
									<span className="text-[14px] font-400 text-blue-800">
										{point}
									</span>
								</div>
							)
						})}
					</div>
				</div>
				<p
					className={clsx(
						" text-[16px] font-400 text-blue-500 transition-opacity",
						!isYearly && "hidden",
					)}>
					*{t("txt_billed_yearly")}
				</p>
				<button
					onClick={(e) => {
						e.preventDefault()
						handleSkip()
					}}
					className="text-[16px] font-400 text-blue-400">
					{t("txt_skip_now")}
				</button>
			</div>
		</div>
	)
}

interface PasswordProperties {
	upperLowerCase: boolean
	symbols: boolean
	numbers: boolean
	enoughCharacters: boolean
}

const MIN_PASSWORD_CHARACTERS = 6
function checkPasswordProperties(
	password: string,
): PasswordProperties {
	const hasUpperCase = password.match(/[A-Z]/) !== null
	const hasLowerCase = password.match(/[a-z]/) !== null
	const symbols =
		password.match(
			/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
		) !== null
	const numbers = password.match(/[0-9]/) !== null
	const enoughCharacters =
		password.length >= MIN_PASSWORD_CHARACTERS

	return {
		upperLowerCase: hasUpperCase && hasLowerCase,
		symbols,
		numbers,
		enoughCharacters,
	}
}

function getPasswordLevelFromProperties(
	properties: PasswordProperties,
): number {
	const {
		upperLowerCase,
		symbols,
		numbers,
		enoughCharacters,
	} = properties
	const requirements = [
		upperLowerCase,
		symbols,
		numbers,
		enoughCharacters,
	]
	const level = requirements.filter(
		(requirement) => requirement,
	).length

	return level
}

interface PasswordCheckPopupProps {
	password: string
}

const levelTexts = [
	"txt_must_have_characters",
	"txt_weak_password",
	"txt_average_password",
	"txt_good_password",
	"txt_strong_password",
]

const PROGRESS_COUNT = 4
export function PasswordCheckPopup(
	props: PasswordCheckPopupProps,
) {
	const { password } = props
	const passwordProperties =
		checkPasswordProperties(password)
	const level = getPasswordLevelFromProperties(
		passwordProperties,
	)

	const { t } = useTranslation()

	const progressCount = new Array(PROGRESS_COUNT).fill(null)

	return (
		<div
			id="password-check"
			className={clsx(
				"peer-focus:pointer-events-auto peer-focus:opacity-100",
				"peer-focus:translate-y-4",
				"peer-focus:desktop:-translate-x-full peer-focus:desktop:-translate-y-1/2",
				"pointer-events-none opacity-0",
				"transition-all duration-300 ease-in-out",
				"absolute left-0 top-full z-40 w-[300px]",
				"desktop:-left-6 desktop:top-1/2",
				"flex flex-col items-stretch gap-4",
				"rounded-[12px] bg-color-cell p-[22px]",
				"[box-shadow:0px_0px_30px_rgba(0,0,0,0.13)]",
				"translate-y-8",
				"desktop:-translate-x-[110%] desktop:-translate-y-1/2",
				"pointer-events-none",
			)}>
			<div className="absolute -right-2 top-1/2 hidden h-6 w-6 -translate-y-1/2 rotate-45 rounded-md bg-color-cell desktop:block" />
			<div className="relative h-[27px]">
				{levelTexts.map((text, index) => (
					<span
						key={index}
						className={clsx(
							"text-[18px] font-600",
							"absolute left-0 top-0",
							index !== level
								? [
										"opacity-0",
										index > level
											? "translate-y-6"
											: "-translate-y-6",
								  ]
								: [
										"opacity-100 delay-100",
										"translate-y-0",
								  ],
							"transition-all duration-200 ease-in-out",
						)}>
						{t(text)}
					</span>
				))}
			</div>
			<div className="flex gap-1">
				{progressCount.map((_, index) => (
					<PasswordCheckProgress
						key={index}
						index={index}
						level={level}
					/>
				))}
			</div>
			<PasswordRecommendations
				properties={passwordProperties}
			/>
		</div>
	)
}

interface PasswordRecommendationsProps {
	properties: PasswordProperties
}

function PasswordRecommendations(
	props: PasswordRecommendationsProps,
) {
	const { properties } = props
	const { t } = useTranslation()

	return (
		<div className="flex flex-col gap-3">
			<span>{t("txt_its_better_to_have")}</span>
			<div className="flex flex-col gap-2 pl-2">
				<PasswordRecommendationTick
					checked={properties.upperLowerCase}>
					{t("txt_upper_and_lower_case")}
				</PasswordRecommendationTick>
				<PasswordRecommendationTick
					checked={properties.symbols}>
					{t("txt_a_symbol")}
				</PasswordRecommendationTick>
				<PasswordRecommendationTick
					checked={properties.numbers}>
					{t("txt_numbers")}
				</PasswordRecommendationTick>
			</div>
		</div>
	)
}

interface PasswordRecommendationTickProps {
	checked: boolean
	children: string
}

function PasswordRecommendationTick(
	props: PasswordRecommendationTickProps,
) {
	const { checked, children } = props

	return (
		<div className="relative pl-4">
			<img
				src={assetUrl("/general/green-tick.svg")}
				alt="checked"
				className={clsx(
					"absolute left-0 top-1/2 -translate-x-1/2 -translate-y-1/2",
					checked ? "opacity-100 delay-100" : "opacity-0",
					"transition-opacity duration-200 ease-in-out",
					"h-4 w-4",
				)}
			/>
			<span
				className={clsx(
					"absolute left-0 top-1/2 -translate-x-1/2 -translate-y-1/2",
					checked ? "opacity-0" : "opacity-100 delay-100",
					"transition-opacity duration-200 ease-in-out",
					"h-[6px] w-[6px] rotate-45 bg-blue-100",
				)}
			/>
			<span>{children}</span>
		</div>
	)
}

interface PasswordCheckProgressProps {
	index: number
	level: number
}

function PasswordCheckProgress(
	props: PasswordCheckProgressProps,
) {
	const { index, level } = props
	const colors = [
		"bg-[transparent]",
		"bg-[#F8665B]",
		"bg-[#F3A23A]",
		"bg-[#47A8F8]",
		"bg-[#09C185]",
	]

	return (
		<div
			className={clsx(
				"h-1 flex-1 overflow-hidden rounded-full bg-color-separator",
			)}>
			<div
				className={clsx(
					index < level
						? ["w-full", colors[level]]
						: ["w-0"],
					"h-full",
					"transition-all duration-300 ease-in-out",
				)}
			/>
		</div>
	)
}

const RightArrow = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20 11C20 11.2652 19.8946 11.5196 19.7071 11.7071L13.7071 17.7071C13.3166 18.0976 12.6834 18.0976 12.2929 17.7071C11.9024 17.3166 11.9024 16.6834 12.2929 16.2929L17.5858 11L12.2929 5.70711C11.9024 5.31658 11.9024 4.68342 12.2929 4.29289C12.6834 3.90237 13.3166 3.90237 13.7071 4.29289L19.7071 10.2929C19.8946 10.4804 20 10.7348 20 11Z"
			fill="var(--icon-color)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19 11C19 11.5523 18.5523 12 18 12L5 12C4.44771 12 4 11.5523 4 11C4 10.4477 4.44771 10 5 10L18 10C18.5523 10 19 10.4477 19 11Z"
			fill="var(--icon-color)"
		/>
	</svg>
)

export const getServerSideProps = wrapSSRAuth(
	async (context, userInfo): Promise<LoginProps> => {
		const { redirect_url, type, expired } = context.query

		let page: LoginPage = "signin"

		try {
			if (type) {
				const parsedType = loginPageSchema.safeParse(type)
				if (
					parsedType.success &&
					parsedType.data !== "verify" &&
					parsedType.data !== "quiz" &&
					parsedType.data !== "success"
				) {
					if (
						parsedType.data === "username" &&
						(userInfo.isAnonymous ||
							!userInfo.details.promptOnboarding)
					) {
						page = "signin"
					} else {
						page = parsedType.data
					}
				}
			}
		} catch (error) {
			console.error("Invalid LoginPage type:", error)
		}

		if (
			userInfo.details.promptOnboarding &&
			!userInfo.isAnonymous
		) {
			page = "username"
		}

		if (
			!userInfo.details.promptOnboarding &&
			!userInfo.isAnonymous &&
			page !== "plan"
		) {
			throw new SSRTemporaryRedirect("/")
		}

		const isExpired = expired === "true"

		const props: LoginProps = {
			redirect_url: redirect_url ?? null,
			type: page,
			expired: isExpired,
		}

		return props
	},
	[],
	{ skipOnboarding: true },
)
